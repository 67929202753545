<template>
  <layout>
    <template slot="body">
      <div class="margin-container">
        <span class="page-title">
          {{ $t('pages.notFound.title') }}
        </span>
        <span>
          {{ $t('pages.notFound.subtitle') }}
        </span>
        <span
          class="nav"
          @click="$router.push({ name: 'sell' })"
        >
          {{ $t('pages.notFound.sell') }}
        </span>
        <span
          class="nav"
          @click="$router.push({ name: 'agents' })"
        >
          {{ $t('pages.notFound.agents') }}
        </span>
        <span
          class="nav"
          @click="$router.push({ name: 'sell-us-process' })"
        >
          {{ $t('pages.notFound.sellUs') }}
        </span>
      </div>
    </template>
  </layout>
</template>
<script>
import Layout from '@/components/layout/Layout';

export default {
  name: 'NotFoundScreen',
  components: {
    Layout,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.page-title {
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 16px;
}

.nav {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
}

.section {
  &--title {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    margin-top: 10px;
  }

  &--subtitle {
    font-weight: 500;
    font-size: 20px;
  }
}

.margin-container {
  margin-top: 150px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 513px);
}

@media only screen and (max-width: 1260px) {
  .margin-container {
    margin-top: 100px;
  }
}
</style>
